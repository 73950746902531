 /**
  * modal
  **/
  .test-modal {
    position: fixed !important;
    left: 10% !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background-color: rgba(105, 102, 102, 0.6) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 10px;
  }

  .test-modal-content {
    width: 77.5% !important;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 10px;
  }

  .test-modal-header {
    padding: 10px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px 10px 0px 0px;
  }

  .test-print {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .test-modal-title {
    margin: 0 !important;
  }

  .test-modal-body {
    padding: 10px !important;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }

  .print-source {
    display: none;
  }

  body > .print-source {
    display: block;
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
  }